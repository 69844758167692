/*
 * @Author: your name
 * @Date: 2021-10-18 09:58:28
 * @LastEditTime: 2022-07-11 13:07:09
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \signser\src\utils\dd_methods.js
 */
// 吧钉钉的jsapi放到一起 统一管理
 /*eslint-disable*/
import dd from 'dingtalk-jsapi';
// 钉钉跳转连接
export const openLink = (url) => {
    dd.ready(() => {
        dd.biz.util.openLink({
          url,//要打开链接的地址
          onSuccess : function(result) {
              /**/
          },
          onFail : function(err) {}
        })
    })
  }
// 钉钉开始录音接口
export const startRecord = () => {
      dd.ready(() => {
        dd.device.audio.startRecord({
          onSuccess : function () {
          },
          onFail : function (err) {
          }
      });
    })
  }
// 隐藏加载 
export const hidePreloader = () => {
    dd.device.notification.hidePreloader({
      onSuccess : function(result) {
          /*{}*/
      },
      onFail : function(err) {}
    })
  }
// 钉钉结束录音接口
export const stopRecord = (setDDValue, getVoiceFileObject) => {
      dd.ready(() => {
        dd.device.audio.stopRecord({
          onSuccess : function(stop){
            console.log('stopstopstopstop调用成功',stop.mediaId, stop.duration);
            if(typeof(setDDValue) === 'boolean') {
              hidePreloader()
              return
            }
              dd.device.audio.translateVoice({
                mediaId : stop.mediaId,
                duration : stop.duration,
                onSuccess : function (res) {
                  console.log(res.content, '这是钉钉翻译的内容');
                  getVoiceFileObject(stop);
                  if(res.content == '' || res.content == '「无内容」') {
                    setDDValue('「语音未识别,请重试」') 
                    return
                  }
                  setDDValue(res.content);
                }
            });
          },
          onFail : function (err) {
            mobileParams('false');
            setDDValue('「语音未识别,请重试」')
          }
      });
    })
  }
  // 设置导航栏title
export const setTitle = () => {
  dd.biz.navigation.setTitle({
    title : 'Signser',//控制标题文本，空字符串表示显示默认文本
    onSuccess : function(result) {
      
    },
    onFail : function(err) {
    }
  });
}
  // 设置导航栏问号图标
export const setIcon = (fun) => {
  dd.biz.navigation.setIcon({
    showIcon : true,//是否显示icon
    iconIndex : 1,//显示的iconIndex, 如上图
    onSuccess : function(result) {
      fun()
    },
    onFail : function(err) {
    }
})
}
 // 手机振动
export const vibrate = () => {
  dd.device.notification.vibrate({
    duration: 50, //震动时间，android可配置 iOS忽略
    onSuccess : function(result) {
    },
    onFail : function(err) {}
})
}
 // IOS设置右侧图标信息
export const setMenuIOS = () => {
  dd.biz.navigation.setMenu({
    backgroundColor : "#ADD8E6",
    textColor : "#ADD8E611",
    items : [
      {
        "id": "1", 
        "text": "刷新", 
        // "url": "http://signser-ali-asr-voice.oss-cn-hangzhou.aliyuncs.com/source%2Fiosrefresh.png?OSSAccessKeyId=LTAI4GJGuRqeqej9kdHq1aMT&Expires=1793345503&Signature=qMUOkztzHupHHt2X5mMhPjvxzlM%3D"
        "url": "http://signser-ali-asr-voice.oss-cn-hangzhou.aliyuncs.com/source/iosrefresh.png"
      }
    ],
    onSuccess: function(data) {
      window.location.reload();
    },
    onFail: function(err) {
    }
  });
}
// 安卓右侧提示信息
export const setMenu = (fun) => {
  dd.biz.navigation.setMenu({
    backgroundColor : "#ADD8E6",
    textColor : "#ADD8E611",
    items : [
      {
        "id": "1", 
        "text": "提示", 
        // "url": "http://signser-ali-asr-voice.oss-cn-hangzhou.aliyuncs.com/source%2Ficon-tips.png?OSSAccessKeyId=LTAI4GJGuRqeqej9kdHq1aMT&Expires=1793268164&Signature=qVm9kRAmrAYbR1jaRvfW001YFn0%3D"
        "url": "http://signser-ali-asr-voice.oss-cn-hangzhou.aliyuncs.com/source/icon-tips.png"

      },
      {
        "id": "2", 
        "text": "刷新", 
        // "url": "http://signser-ali-asr-voice.oss-cn-hangzhou.aliyuncs.com/source%2Frefresh.png?OSSAccessKeyId=LTAI4GJGuRqeqej9kdHq1aMT&Expires=1793268101&Signature=278BWZwOebIsxO%2FIz8z6zLKkH%2FI%3D"
        "url": "http://signser-ali-asr-voice.oss-cn-hangzhou.aliyuncs.com/source/refresh.png"
      }
    ],
    onSuccess: function(data) {
      if(data.id == '1') {
        fun()
      } else {
        window.location.reload();
      }
    },
    onFail: function(err) {
    }
  });
}
// 禁止ios webview滚动
export const webViewBounce = () => {
  dd.ui.webViewBounce.disable();
}
// 加载
export const showPreloader = () => {
  dd.device.notification.showPreloader({
    text: "正在加载..", //loading显示的字符，空表示不显示文字
    showIcon: true, //是否显示icon，默认true
    onSuccess : function(result) {
        /*{}*/
    },
    onFail : function(err) {}
  })
}

export const openLink2 = () => {
  dd.biz.conference.openLink({
    type: 'link',
    url: 'https://knowledgecs.paiago.com'
  });
}


