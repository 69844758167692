/*eslint-disable*/
// 移动端底部输入框组件
import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import '@/style/m-input.scss';
import keyboard from '@/images/keyboard.png';
import MobileTimeShort from '../MobileTimeShort';
import voice from '@/images/voice.png';
import voiceSvg from '@/images/voice.svg';
import qs from 'querystring';
import uncheckedSend from '@/images/send.png';
import mobile_send from '@/images/mobile_send.png'
import { Input, toast } from '@chatui/core';
// eslint-disable-next-line
import MobileMask from '@/containers/MobileMask';
import { startRecord, stopRecord, vibrate, showPreloader, hidePreloader } from '../../utils/dd_methods';
import { setSession, getSession } from '../../utils/utils';
import Cancel from '../Cancel';
import Context from '@/utils/context';

let stop; // 结束录音返回的参数
let move = false // 是否调用了touchMove false 未调用 true 调用

export default function MInput({mobileSend, mobileVoiceFun, error, silderContent, isShowFooterInput}) {
  MInput.propTypes = {
    mobileVoiceFun: PropTypes.func,
    mobileSend: PropTypes.func,
    silderContent: PropTypes.string,
    error: PropTypes.bool,
    isShowFooterInput: PropTypes.bool,
  };
  const device = useContext(Context);
  const search = qs.parse(window.location.search.slice(1)) || {};
  // eslint-disable-next-line no-unused-vars
  const [inputType, setInputType] = useState(getSession('inputType') || false); // 输入框状态 true为文字输入 false为语音输入 默认文字
  const [mobileVoice, setMobileVoice] = useState(false); // 手机端是否开启录音 true 是 false 否
  const [mobileVoiceTime, setMobileVoiceTime] = useState(60000); // 记录录音时长 默认60s // 60000
  const [mobileTime, setMobileTime] = useState(null); // 手机端计时器
  const [mobileTimeShortStatus, setMobileTimeShortStatus] = useState(false); // 手机端 说话太短页面控制 false 不显示 true 显示 默认false
  const [autoSizeValue, setAutoSizeValue] = useState('');  
  const [disabled, setDisabled] = useState(true); 
  const [maxLengthStatus, setMaxLengthStatus] = useState(false);
  const [div, setDiv] = useState(false) // 按住说话状态 默认false没按住
  const [ddValue, setDDValue] = useState(''); // 钉钉识别的说话内容
  const inputRef = React.useRef(null);
  const [focusTime, setFocusTime] = useState(null);
  const [clearTime,  setClearTime] = useState(null);
  const mInputRef = useRef(null); // m-input的dom对象
  const [cancel, setCancel] = useState(false);
  const [divContent, setDivContent] = useState('按住说话');
  /**
   * @description 设置输入框状态
   * @returns 结果取反
   */
  function changeInputType() {
    const search = qs.parse(window.location.search.slice(1)) || {};
    // 不是从ws进入
    if (!(search.hasOwnProperty('isWSEnter') && search.isWSEnter === 'true')) {
      setInputType(!inputType);
    }
    // console.log(11111111111, search);
  }
  /**
   * @description 手机开始录音回调
   */
  function mobileStartVoice() {
    document.getElementById('chatBox').classList = 'chatBox select-none';
    setMobileVoiceTime(60000)
    move = false;
    if(error) {
      toast.fail('检测不到登录信息，请重试');
      return
    }
    vibrate();
    setDivContent('松开发送')
    setDiv(true);
    setMobileVoice(true);
    const timer = setInterval(() => {
      setMobileVoiceTime((params) => {
        if (params <= 500) {
          setDivContent('按住说话')
          setDiv(false);
          clearInterval(timer);
          setMobileVoice(false);
          if(device === 'iPhone') {
            showPreloader();
          }
          stopRecord(setDDValue, getVoiceFileObject);
          move = true
        }
        return params -= 500;
      });
    }, 500);
    setMobileTime(timer);
    startRecord();
  }
  useEffect(() => {
    if (silderContent) {
      setAutoSizeValue(silderContent);
      setDisabled(false);
    }
  }, [silderContent]);
  /**
   * @description 获取钉钉录音暂停的参数
   * @param {} params 钉钉录音暂停的参数
   */
  function getVoiceFileObject(params) {
    stop = params
  }
  /**
   * @description 录音结束后做了什么
   */
  async function mobileVoiceEnd() {
    document.getElementById('chatBox').classList = 'chatBox';
      if(move) {
        setCancel(false);
        setDiv(false);
        setDivContent('按住说话')
        return
      }
      setDivContent('按住说话')
      setDiv(false);
      clearInterval(mobileTime);
      setMobileVoice(false);
      if(device === 'iPhone') {
        showPreloader();
      }
      if(60000 - mobileVoiceTime  <= 500) {
        setMobileTimeShortStatus(true);
        const time = setTimeout(() => {
          stopRecord(true);
          clearInterval(time);
          setMobileTimeShortStatus(false);
        }, 500)
        setClearTime(time);
        return
      }
      stopRecord(setDDValue, getVoiceFileObject);
  }
  // 钉钉语音传给app页面
  useEffect(() => {
    console.log('调用了');
    setInputType(getSession('inputType'));
    if(ddValue) {
      setSession('inputType',inputType);
      mobileVoiceFun(ddValue, stop);
      if(device === 'iPhone') {
        hidePreloader()
      }
    }
  }, [ddValue]);

  useEffect(() => {
    return () => {
      clearTimeout(clearTime);
    }
  }, []);
  /**
   * @description 输入框改变触发
   * @param {} event 输入框的值
   */
  function autoSizeChange(value) {
    Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
    const TEXTAREAVALUE = value
    if (TEXTAREAVALUE.trim()) {
      setDisabled(false);
      if (value.length >= 201) {
        setMaxLengthStatus(true);
        setDisabled(true)
      } else {
        setMaxLengthStatus(false);
      }
    } else {
      setMaxLengthStatus(false);
      setDisabled(true);
    }
    setAutoSizeValue(value);
  }
  /**
   * @description 获取输入框焦点的时候吧页面向上
   */
  function onFocus() {
      const time = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].style.height = window.innerHeight + 'px';
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300)
      setFocusTime(time);
  }
  // 移动端发送内容,每次发送完保存输入框状态
  function touchSend() {
    setSession('inputType',inputType);
    disabled ? '' : mobileSend(autoSizeValue);
  }
  /**
   * @description 手指上划取消录音
   * @param {*} e 事件对象
   */
  function onTouchMove(e) {
    const height = document.body.offsetHeight - mInputRef.current.offsetHeight
    if (e.touches[0].clientY < height) {
      setMobileVoice(false);
      clearInterval(mobileTime);
      setDivContent('松开取消')
      setCancel(true);
      move = true
    }
  }
  // 手机端点击发送
  // function textareaKeyDown(e) {
  //   if (!e.shiftKey && e.keyCode === 13) {
  //     mobileSend(autoSizeValue);
  //     setAutoSizeValue('');
  //   }  
  // }

  return (
    <>
      {
        isShowFooterInput ? (
          <div className="m-input" id="m-input" ref={mInputRef}>
            <MobileMask mobileVoice={mobileVoice} mobileVoiceTime={mobileVoiceTime} />
            <MobileTimeShort mobileTimeShortStatus={mobileTimeShortStatus} />
            <Cancel cancelSend={cancel} />
            <div className="left">
              {
                search.hasOwnProperty('isWSEnter') && search.isWSEnter === 'true' ? (
                  <div onTouchStart={changeInputType}>
                    <img src={voiceSvg} alt="" />
                  </div>
                ) : (
                  <div onTouchStart={changeInputType}>
                    <img src={inputType ? keyboard : voice} alt="" />
                  </div>
                )
              }
          
            </div>
            <div className={div ? 'x-input select-say' : 'x-input'}>
              {/* onKeyDown={(e) => textareaKeyDown(e)} */}
              {!inputType ? <Input ref={inputRef} autoSize onFocus={onFocus} value={autoSizeValue} onChange={(e) => autoSizeChange(e)} placeholder="Signser为您服务，请输入您的问题" /> : <div className="say" onTouchMove={onTouchMove} onTouchStart={mobileStartVoice} onTouchEnd={mobileVoiceEnd}>{divContent}</div>}        
              {/* {!inputType ? <Input  enterkeyhint="send" type="search" ref={inputRef} autoSize onFocus={onFocus} value={autoSizeValue} onChange={(e) => autoSizeChange(e)} placeholder="Signser为您服务，请输入您的问题" /> : <div className="say" onTouchMove={onTouchMove} onTouchStart={mobileStartVoice} onTouchEnd={mobileVoiceEnd}>{divContent}</div>}         */}
            </div>
            <div className={maxLengthStatus ? 'maxLength' : 'none'}>
                <span style={{ color: '#FF475A' }}>{autoSizeValue.length}</span>
                <span className="a">/200</span>
              </div>
            <div className="right" onTouchStart={() => touchSend()}>
              <div>
                {
                  !disabled  ? <img src={mobile_send} alt="" /> : <img src={uncheckedSend} alt="" />
                }    
              </div>
            </div>
          </div>
        ) : ''
      }
    </>
  );
}
