/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
/*
 * @Author: your name
 * @Date: 2021-10-14 17:55:17
 * @LastEditTime: 2022-07-12 17:04:01
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \signser\src\utils\utils.js
 */
// eslint-disable-next-line import/extensions
import { getBuriedpoint } from '@/apis/http';

// 对话流页面/设置预算
// eslint-disable-next-line import/prefer-default-export
export const setRemindPoint = (origin = 'pc', pcSend, txt) => {
  let params = {};
  params.origin = origin;
  params.p = origin === 'pc' ? '/pc/Signser/dialog/budget/switch_remind/set' : '/Signser/dialog/budget/switch_remind/set';
  getBuriedpoint(params);
  pcSend(txt);
};
