/*eslint-disable*/
import React, { useState, useEffect, useContext, useRef } from 'react';
import Chat, { useMessages, toast } from '@chatui/core';
import signser from '@/images/signser.png';
import Robot from './images/robot_logo.png';
import '@/style/App.scss';
import qs from 'querystring';
import dd from 'dingtalk-jsapi';
// import Silder from '@/containers/Silder';
import FooterInput from '@/containers/FooterInput';
import maikefengPC from './images/maikefengPC.png';
import { ossKey, userInfo, asr, chat, firstTime, entity, recommendNoAnser, RecommendMoreIntentMessages, getBuriedpoint } from '@/apis/http';
import { v1 } from 'uuid';
import initSinglePlupload from '@/utils/upload';
import { setSession, getSession, isJSON, getTraceId, getWsEntrance } from '@/utils/utils';
import Recorder from 'js-audio-recorder';
import More from '@/containers/More';
import MInput from '@/containers/MInput';
import Text from '@/containers/Text';
import Recommend from './containers/Recommend';
import MobileNav from './containers/MobileNav';
import MobileKnowledge from './containers/MobileKnowledge';
import Knowledge from './containers/Knowledge';
import { setTitle, setIcon, setMenu, webViewBounce, setMenuIOS } from './utils/dd_methods';
import defaultAvatar from '@/images/default.png';
import Context from './utils/context';
import { useTime, useDebounce, useNetWork, useMount } from './utils/hooks';
// import MobileBeforeMessageList from './containers/MobileBeforeMessageList';
// import PCBeforeMessageList from './containers/PCBeforeMessageList';
import useNls from './utils/nls';
import NextPage from './containers/NextPage';
import MobileQues from './containers/MobileQues';
import allow from './utils/allow';
import QRcode from './containers/QRcode';
import initEntity from './apis/initEntity'; // 更新实体

let transID = null; // 过渡动画id删除过度动画
let textID = null; // 普通会话id 出现小红点时更新消息状态
let update = false // 是否更新 吧过度动画消息更新为最新的
let hasTime = false; // 记录上一次发送消息的时间 没3分钟出现一次时间
const SAYONE = '抱歉，您的问题Signser暂时无法提供答案，您可以选择';
console.log(window.flutter_inappwebview, allow());
const App = () => {
  console.log(process.env.REACT_APP_VERSION, '这是版本号');
  const device = useContext(Context); // 首页传的设备类型;
  let realTime = null; // 实时语音实例
  // const initialMessages = (['Android', 'iPhone'].indexOf(device) >= 0 ? [
  //   {
  //     type: 'system',
  //     content: {
  //       text: '智能机器人Signser为您服务',
  //     },
  //   },
  // ] : [
  //   {
  //     type: 'system',
  //     content: {
  //       text: '智能机器人Signser为您服务'
  //     },
  //   },
  // ]); // 初始化消息内容
  // v2.3.0 内容: 快捷查询 项目预算设置 人工客服
  const quickReplies = ['Android', 'iPhone'].indexOf(device) >= 0 ?
    [
      // { name: '常见问题' },
      // { name: '查逾期应收' },
      // { name: '查到期应收' },
      // { name: '查部门销售额' },
      // { name: '查回款排名' },
      { name: '快捷查询', getBuriedpointParams: '/Signser/dialog/click/quick_query' },
      { name: '消息提醒设置', getBuriedpointParams: '/Signser/dialog/click/project_budget' },
      { name: '人工客服', getBuriedpointParams: '/Signser/dialog/click/customer_service' },
    ] : [
      // { name: '查逾期应收' },
      // { name: '查到期应收' },
      // { name: '查部门销售额' },
      // { name: '查回款排名' },
      { name: '快捷查询', getBuriedpointParams: '/pc/Signser/dialog/click/quick_query' },
      { name: '消息提醒设置', getBuriedpointParams: '/pc/Signser/dialog/click/project_budget' },
      { name: '人工客服', getBuriedpointParams: '/pc/Signser/dialog/click/customer_service' },
    ];
  const kfID = useRef(null);
  const { messages, appendMsg, setTyping, deleteMsg, updateMsg } = useMessages(); // initialMessages 初始化消息列表 chatui提供的方法 具体请去那边看
  const [none, setNone] = useState(false);  //侧边栏状态  true 侧边栏消失 false 出现侧边栏
  const [maskStatus, setMaskStatus] = useState(false) // 遮罩层状态  true 遮罩层消失 false 出现遮罩层
  const [voiceTime, setVoiceTime] = useState(60000) // 录音时长 最长60秒
  const [timer, setTimer] = useState() // 定时器对象
  const [recorder, setRecorder] = useState(null); // recorder录音对象实例
  const [uploader, setUploader] = useState(null); // uploader oss上传对象实例
  const [knowledgeId, setKnowledgeId] = useState(null); // 侧边栏传过来的knowledge
  const [sessionId, setSessionId] = useState(null); // 会话ID
  const [sendType, setSendType] = useState(1); // 上传类型 1 文本  2 翻译 	
  const [transType, setTransType] = useState(0); // 翻译类型 0	无翻译	1	阿里一句话识别	2 钉钉一句话识别
  const [voiceFileObject, setVoiceFileObject] = useState(''); // 	音频文件信息 详情看接口文档
  const [transContent, setTransContent] = useState(false); // 翻译内容 或 普通文字内容
  const [knowledgeValue, setKnowledgeValue] = useState(false); // knowledge内容
  const [inputType, setInputType] = useState(true); // chat接口是否请求完成 false未完成  true完成
  const [during, setDuration] = useState(false); // 一句话识别录音时间
  const [tipStatus, setTipStatus] = useState(false); // 手机导航栏标题是否出现
  let { add, chatTime } = useTime((new Date()).valueOf()); // 3分钟出现一次时间
  const [error, setError] = useState(false);
  const [realTimeContent, setRealTimeContent] = useState({}); // 实时语音内容
  const [contentStatus, setContentStatus] = useState(0); // 点击我说完了发送语音状态
  const [silderContent, setSilderContent] = useState(null); // 侧边栏的指引内容
  const [macStatus, setMacStatus] = useState(false); // 是否出现苹果二维码
  const [groupUrl, setGroupUrl] = useState('');
  const [endContent, setEndContent] = useState(''); // nls 里边sentenceEnd事件完成发送的内容只有sentenceEnd完成了才发送内容
  const [isShowFooterInput, setIsShowFooterInput] = useState(true); //是否显示输入框
  useNetWork();
  // 接收allAccount的回调函数
  function allAccountCallback() {
    setTyping(true);
  }
  realTime = useNls((params, status) => {
    setRealTimeContent({
      content: params, // 语音内容
      status // 是否发送
    });
  }, noneMask, (params, status) => {
    if (status) {
      setContentStatus(true);
    }
    setEndContent(params);
  });
  useMount(() => {
    console.log('useMount触发了', contentStatus);
    if (endContent && contentStatus) {
      send(endContent);
    }
  }, [endContent, contentStatus]);
  /**
   * @description 刚进页面发请求获取header头
   */
  useEffect(async () => {
    setSession('userAuth', null); // 清空auth
  setSession('trace_id', v1());
  const search = qs.parse(window.location.search.slice(1)) || {};
    // ws推送消息 进入
  if (search.isWSEnter == 'true') {
    // 设置项目预算 文本框输入
    // if (search.wsEnterType === 'businessAccounting') setIsShowFooterInput(true);
    getWsEntrance(pcSend, appendMessage, ['Android', 'iPhone'].indexOf(device) >= 0 ? 'web' : 'pc');
  } else {
    // setIsShowFooterInput(true);
    appendMsg({
      type: 'text',
      content: {
        text: JSON.stringify({
          data: {
            title: 'hi，我是您的数字助理Signser，我能解决您在账款管家遇到的一切数据问题！快来向我提问吧！',
          },
          code: 0
        })
      },
      hasTime: true,
      user: { avatar: Robot },
    })
    // 快捷查询逻辑处理
    // 1 从signser图标进入 移除推荐
    // 替换为 快捷查询的页面渲染。此处不通过调用chat 接口来获取快捷查询结果。从本地和本地方法处理，（这块的不进行埋点）
    if (search.isRecommendEnter === 'false') {
      // 快捷查询
      appendMsg({
        type: 'text',
        content: {
          text: JSON.stringify({
            data: {"info":{"list":[]},"title":"","is_quick_query":1,"is_text":0},
            code: 0
          })
        },
        hasTime: true,
      })
    } else {
      // 2 从去看看进入 移除 推荐语句
      // 3从去看看更新提示进入 1版本更新 2发送话术
      if (search.recommendMessageType === '1') {
        appendMsg({
          type: 'text',
          content: {
            text: JSON.stringify({
              data: {
                is_chart: 100,
              },
              code: 0
            })
          },
          hasTime: true,
          user: { avatar: Robot },
        })
      } else {
        // 从去看看进入进入 recommendMessageType = 2发送话术
        const user = getSession('user') || {};
        // eslint-disable-next-line no-prototype-builtins
        if (!user.hasOwnProperty('user_id')) {
          const info = await userInfo();
          setSession('user', info);
        } 
        pcSend(search.recommendChatMessage);
      }
    }
    
    /**
    * @description 查看用户是否第一次进入Signser
    */ 
    // firstTime().then(res => {
    
    //   let firstTitle = res.if_first_time === 1 ? 'hi，我是Signser，是您在账款管家的数字助理，我出生于账款管家，主要能力是大数据分析、机器学习和AI。您有什么问题可以随时呼唤我哦~' 
    //   : 'hi，我是您的数字助理Signser，我能解决您在账款管家遇到的一切数据问题！快来向我提问吧！';
    //   appendMsg({
    //     type: 'text',
    //     content: {
    //       text: JSON.stringify({
    //         data: {
    //           title: firstTitle,
    //         },
    //         code: 0
    //       })
    //     },
    //     hasTime: true,
    //     user: { avatar: Robot },
    //   })
    //   appendMsg({
    //     type: 'text',
    //     content: {
    //       text: JSON.stringify({
    //         data: {
    //           // title: 'hi，我是Signser，是您在账款管家的数字助理，我出生于账款管家，主要能力是大数据分析、机器学习和AI。您有什么问题可以随时呼唤我哦~',
    //           is_chart: 100,
    //           // is_text: 1
    //         },
    //         code: 0
    //       })
    //     },
    //     hasTime: true,
    //     user: { avatar: Robot },
    //   })
    // });
    // appendMessage({ text: JSON.stringify({ data: {"data":{"info":{"list":[]},"title":"","is_quick_query":1,"is_text":0},"code":0,"msg":"ok"}, code: 0 }) }, 'left', 'text');
  }

    if (process.env.REACT_APP_DD === 'true') {
      if (['Android', 'iPhone'].indexOf(device) >= 0) {
        setNone(true);
        // 设置导航栏标题
        setTitle();
        // 禁止ios webview滚动
        webViewBounce();
        if (device === 'iPhone') {
          setMenuIOS();
          // 设置导航栏问号图标
          setIcon(() => {
            setTipStatus((params) => !params);
          });
        } else {
          setMenu(() => {
            setTipStatus((params) => !params);
          })
        }
      }
    } else {
      if (['Android', 'iPhone'].indexOf(device) >= 0) {
        setNone(true);
      }
    }
    // 网页中不允许请求接口
    if (process.env.REACT_APP_TOKEN || device) {
      /**
       * @description 获取用户信息接口
       */
      userInfo().then(res => {
        if (res?.data?.code) {
          setError(true);
          if (res.data.code.toString().indexOf('401') === 0) {
            toast.fail('检测不到登录信息，请重试');
          }
          if (res.data.code.toString().indexOf('402') === 0) {
            toast.fail(res.data.msg);
          }
          return
        }
        setError(false);
        setSession('user', res);
      });
    }
    // initEntity(); // 更新实体接口
    // 调用OSS上传api
    let uploader = initSinglePlupload();
    setUploader(uploader);
    uploader.init();
    // 实例化录音 调用录音的一些api
    let recorder = new Recorder({
      sampleBits: 16, // 采样位数，默认是16
      sampleRate: 16000, // 采样频率，浏览器默认的，我的chrome是48000
      numChannels: 1, // 声道数，默认是1
    });
    setRecorder(recorder);

    return () => {
      timer && clearInterval(timer);
      recorder.destroy().then(function () {
        recorder = null;
      });
    }
  }, []);
  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (e.data === '1') {
        if (timer) {
          recorder.stop();
          clearInterval(timer);
          setMaskStatus(false)
          setDuration(null);
          setMaskStatus(false);
        }
      }
    });
  }, [timer])
  /**
   * @description 获取Recommend和手机端里边的KnowledgeId和内容
   * @param {*} title Knowledge内容
   * @param {*} id KnowledgeId
   */
  function getKnowledgeId(title, id) {
    if (error) {
      toast.fail('检测不到登录信息，请重试');
      return
    }
    setKnowledgeValue(title);
    setKnowledgeId(id);
    if (title === knowledgeValue) {
      update = false;
      send(title);
    }
  }
  /**
   * @description chatui自带的消息渲染函数
   * @param {*} msg 消息内容和类型
   * @returns 以哪个组件渲染
   */
  function renderMessageContent(msg) {
    const { type } = msg;
    let value = '';
    switch (type) {
      case 'trans':
        transID = msg._id;
        value = <div className="trans" id="trans">
          <div className="a01"></div>
          <div className="a01 a02"></div>
          <div className="a01"></div>
        </div>
        break;
      case 'text':
        textID = msg._id;
        value = <Text setIsShowFooterInput={setIsShowFooterInput} macCode={macCode} deleteMsg={deleteMsg} msg={msg} pcSend={pcSend} failSend={failSend} allAccountCallback={allAccountCallback} appendMessage={appendMessage} updateMsg={updateMsg} sendFAQ={sendFAQ} textID={textID} />;
        break;
      case 'update':
        value = <Text msg={msg} />;
        break;
      case 'card':
        value = <More getKnowledgeId={getKnowledgeId} />;
        break;
      case 'Recommend':
        value = <Recommend msg={msg} getKnowledgeId={getKnowledgeId} />
        break;
      case 'Knowledge':
        value = (['Android', 'iPhone'].indexOf(device) >= 0 ? <MobileKnowledge msg={msg} pcSend={pcSend} /> : <Knowledge msg={msg} pcSend={pcSend}/>)
        break;
      case 'nextPage':
        value = <NextPage id={msg._id} msg={msg.content.text} updateMsg={updateMsg} nextPageType={msg.nextPageType} allAccountCallback={allAccountCallback} appendMessage={appendMessage} />
        break;
      case 'Mobile':
        value = <MobileQues id={msg._id} msg={msg.content.text} nextPageType={msg.nextPageType} allAccountCallback={allAccountCallback} appendMessage={appendMessage} />
        break;
      case 'kf':
        kfID.current = msg._id;
        value = <Text setIsShowFooterInput={setIsShowFooterInput} kfID={kfID.current} deleteMsg={deleteMsg} msg={msg} pcSend={pcSend} failSend={failSend} allAccountCallback={allAccountCallback} appendMessage={appendMessage} updateMsg={updateMsg} sendFAQ={sendFAQ} />;
        break;
      default:
        break;
    }
    return value;
  }
  /**
   * @description 接收小红点类型的内容
   * @param {*} value  消息内容
   * @param {*} id     消息ID
   */
  function failSend(value, id) {
    const user = getSession('user') || {};
    updateMsg(id, {
      type: 'text',
      content: {
        text: value,
      },
      user: { avatar: user.avatar ? user.avatar : defaultAvatar },
      position: 'right',
      status: false,
    });
    update = true;
    send(value)
  }
  /**
   * @description 添加消息
   * @param {*} type  消息类型 
   * @param {*} text  消息内容 接口返回
   * @param {*} position right 为用户发送消息 left 为机器人发送消息
   * @param {*} avatar  用户头像
   */
  function appendMessage(text, position, type = 'text', avatar = Robot, chatType = true, nextPageType = '') {
    if (((new Date()).valueOf() - chatTime) > 180000 && hasTime === false) {
      add((new Date()).valueOf());
      hasTime = true;
    } else {
      hasTime = false;
    }
    appendMsg({
      type,
      content: text,
      position,
      user: { avatar: avatar },
      hasTime,
      chatType,
      nextPageType,
    });
  }
  /**
   * @description 点击快捷短语回调
   * @param {*} param0 快捷短语的内容
   */
  function onQuickReplyClick({ name, getBuriedpointParams }) {
    // 埋点
    if (name !== '消息提醒设置') {
      getBuriedpoint({ origin: ['Android', 'iPhone'].indexOf(device) >= 0 ? 'web' : 'pc', p: getBuriedpointParams});
    }
    if (['Android', 'iPhone'].indexOf(device) >= 0) {
      if (name === '常见问题') {
        appendMsg({
          type: 'card'
        })
      } else {
        if (error) {
          toast.fail('检测不到登录信息，请重试');
          return
        }
        setInputType(false);
        setSendType(1);
        setVoiceFileObject('');
        setTransType(0);
        setKnowledgeId(null);
        setTransContent(name);
        if (name === transContent) {
          update = false;
          send(name);
        }
      }
    } else {
      if (name === '常见问题') {
        setNone(!none);
      } else {
        if (error) {
          toast.fail('检测不到登录信息，请重试');
          return
        }
        setInputType(false);
        setSendType(1);
        setVoiceFileObject('');
        setTransType(0);
        setKnowledgeId(null);
        setTransContent(name);
        if (name === transContent) {
          update = false
          send(name);
        }
      }
    }
  }
  /**
   * @description chatui自带的方法
   * @returns 渲染顶部消息区域
   */
  function childSetSilder(params) {
    setNone(params)
  }
  /**
   * @description 语音转文字
   * @param {*} formdata 上传文件的formdata对象
   * @param {*} FileName 上传文件的文件名字
   * @param {*} size 上传文件的文件大小
   */
  function asrFun(formdata, FileName, size) {
    if (error) {
      toast.fail('检测不到登录信息，请重试');
      return
    }
    asr(formdata, {
      'Content-Type': 'multipart/form-data'
    }).then((res) => {
      if (res.status === 20000000) {
        setTransType(1)
        setSendType(2)
        setVoiceFileObject(JSON.stringify({
          from: 2,
          url: `http://signser-ali-asr-voice.oss-cn-hangzhou.aliyuncs.com/dev/ask/2021/10/27/${FileName}`,
          size: size,
          duration: 60000 - voiceTime,
          name: FileName
        }))
        setKnowledgeId(null);
        if (res.result) {
          setTransContent(res.result);
          deleteMsg(transID)
          setMaskStatus(false);
          setDuration(null);
          if (res.result === transContent) {
            update = false;
            send(res.result);
          }
        } else {
          setMaskStatus(false);
          setDuration(null);
          const user = getSession('user') || {};
          updateMsg(transID, {
            type: 'update',
            content: {
              text: '「语音未识别,请重试」',
            },
            user: { avatar: user.avatar ? user.avatar : defaultAvatar },
            position: 'right',
          })
        }
      }
    }).catch((err) => {
      console.log(err);
      const user = getSession('user') || {};
      updateMsg(transID, {
        type: 'update',
        content: {
          text: '「语音未识别,请重试」',
        },
        user: { avatar: user.avatar ? user.avatar : defaultAvatar },
        position: 'right',
      })
    })
  }
  const FileName = new Date().getTime();
  /**
   * @description 一句话识别
   * @param {*} e 事件对象
   */
  function oneTalk(e) {
    let num;
    if (typeof e === 'number') {
      num = e
    } else {
      num = voiceTime
      e?.stopPropagation();
    }
    clearInterval(timer);
    recorder.stop();
    let file = recorder.getPCMBlob()
    let audio = new File([file], FileName, { type: "" });
    const formdata = new FormData()
    formdata.append('file', file)
    if ((60000 - num) > 500) {
      setDuration(null);
      setMaskStatus(false);
      const user = getSession('user') || {};
      appendMessage('', 'right', 'trans', user.avatar || defaultAvatar);
      // 语音转文字
      asrFun(formdata, FileName, audio.size);
      // oss直传
      ossKey().then(res => {
        const new_multipart_params = {
          OSSAccessKeyId: res.accessid,
          success_action_status: '200',
          key: res.dir + '/${filename}',
          expire: res.expire,
          policy: res.policy,
          signature: res.signature
        }
        uploader.setOption({
          'multipart_params': new_multipart_params
        })
        uploader.addFile(audio, FileName.toString());
        uploader.bind('FilesAdded', (upload, files) => {
          uploader.start();
        });
      })
    } else {
      setMaskStatus(false);
      setDuration(true);
    }
  }
  /**
   * @description 点击我说完了触发
   * @param {*} type type为nls则是用户手动点击否则是时间到了自动点击
   */
  function noneMask(type) {
    if (type !== 'nls') {
      setSendType(2);
      setTransType(3);
      realTime?.stop();
      setContentStatus(contentStatus + 1);
    }
    setMaskStatus(false);
  }
  /**
   * @param {*} value 用户输入内容 快捷短语内容 侧边栏内容等
   */
  function send(value) {
    setContentStatus(false);
    if (error) {
      toast.fail('检测不到登录信息，请重试');
      return
    }
    const user = getSession('user') || {};
    if (!update) {
      let text = value
      if (Object.prototype.toString.call(value) === '[object Object]') {
        if (value.hasOwnProperty('extends')) {
          text = value.value;
        }
      } 
      user.avatar ? appendMessage({ text: text }, 'right', 'text', user.avatar) : appendMessage({ text: text }, 'right', 'text', defaultAvatar);
    }
    setTyping(true);
    sendChat(value, user);
    setEndContent('');
  }
  /**
   * @description 调用对话接口接口服务端返回的对话内容
   * @returns 
   */
  function sendChat(value, user) {
    // if (!isShowFooterInput) setIsShowFooterInput(true);
    const params = {
      send_body: value,
      session_id: sessionId,
      knowledge_id: knowledgeId,
      sender_id: user.user_id?.toString(),
      sender_nick: user?.name,
      trace_id: getTraceId(),
      send_type: sendType,
      trans_type: transType,
      file: voiceFileObject,
      sender_company_id: user.company_id,
    }
    if (Object.prototype.toString.call(value) === '[object Object]') {
      if (value.hasOwnProperty('extends')) {
        params.extends =  value.extends;
        params.send_body = value.value
      }
    }
    chat(params).then((res) => {
      if (res?.code) {
        appendMessage({ text: res.msg, RelatedKnowledges: res.Messages[0]?.Knowledge?.RelatedKnowledges, MessageId: res.MessageId }, 'left', 'text');
        return
      }
      if (res && res.length === 0) {
        appendMessage({
          text: JSON.stringify({
            data: {
              title: `${SAYONE}`,
              is_cs: 1,
              is_text: 1,
            },
            code: 0
          },)
        }, 'left', 'text');
        // 返回空数组也是无答案推荐
        // 设置loading
        setTyping(true);
        recommendNoAnser({message: value}).then((res)=> {
          if (res && res.info && Array.isArray(res.info.list) && res.info.list.length) {
            appendMessage({ text: JSON.stringify( {data: Object.assign({}, res, {recommendType: 'is_no_answer'} ), code: 0 }) }, 'left', 'text');
          }
          setTyping(false);
        }).catch(()=> {
          setTyping(false);
        })

        return;
      }
      if (res && res.Messages.length) {
        // 判断is_logout存在且=1 则下一条提问不继承sessionId 
        if (res.Messages[0].Type === 'Text') {
          if (res.Messages[0].Text.Content !== '' && !!isJSON(res.Messages[0].Text.Content)) {
            const dataObj = isJSON(res.Messages[0].Text.Content) && JSON.parse(res.Messages[0].Text.Content);
            if (dataObj.code === 0) {
              dataObj.data.is_logout == '1' ? setSessionId(null) : setSessionId(res.SessionId);
            }
            // if (!(dataObj.code === 0 && dataObj.data.is_logout === '1')){
            //   setSessionId(res.SessionId);
            // } 
          } else {
            setSessionId(res.SessionId);
          }
        } else {
          setSessionId(res.SessionId);
        }

        if (res.Messages[0].Type === 'Text') {
          if (res.Messages[0].Text.Content === '') {
            appendMessage({
              text: JSON.stringify({
                data: {
                  title: `${SAYONE}`,
                  is_cs: 1,
                  is_text: 1,
                },
                code: 0
              })
            }, 'left', 'text');
            // 返回空内容也是无答案推荐
            // 设置loading
            setTyping(true);
            recommendNoAnser({message: value}).then((res)=> {
              if (res && res.info && Array.isArray(res.info.list) && res.info.list.length) {
                appendMessage({ text: JSON.stringify( {data: Object.assign({}, res, {recommendType: 'is_no_answer'} ), code: 0 }) }, 'left', 'text');
              }
              setTyping(false);
            }).catch(()=> {
              setTyping(false);
            })
            return;
          }
          // 处理接口返回 res.Messages[0].Text.Content 不为JSON情况
          if (!isJSON(res.Messages[0].Text.Content)) {
            try {
              // 处理JSON问题
              res.Messages[0].Text.Content=JSON.parse("\""+res.Messages[0].Text.Content+"\"");
              // res.Messages[0].Text.Content = eval("'" + res.Messages[0].Text.Content + "'");
              // res.Messages[0].Text.Content = JSON.parse(JSON.parse(res.Messages[0].Text.Content));
            } catch (error) {
            }
          }
          
          if (!isJSON(res.Messages[0].Text.Content)) {
          } else {
            const resultObj = isJSON(res.Messages[0].Text.Content) && JSON.parse(res.Messages[0].Text.Content);
            if (resultObj.code.toString().indexOf('422') === 0 || resultObj.code.toString().indexOf('400') === 0 || resultObj.code.toString().indexOf('401') === 0) {
              appendMessage({ text: res.Messages[0].Text.Content, RelatedKnowledges: res.Messages[0]?.Knowledge?.RelatedKnowledges, MessageId: res.MessageId }, 'left', 'text');
              // 渲染成功推荐 
              if (resultObj.data.hasOwnProperty('next')) {
                appendMessage({ text: JSON.stringify({ data: resultObj.data['next'], code: 0 }) }, 'left', 'text');
              }
              return
            } else if (resultObj.code === 0) {
              // 快捷查询
              if (resultObj.data.is_quick_query === 1) {
                appendMsg({
                  type: 'text',
                  content: {
                    text: JSON.stringify({
                      data: resultObj.data,
                      code: 0
                    })
                  },
                  hasTime: true,
                })
              } else {
                appendMessage({ text: res.Messages[0].Text.Content, RelatedKnowledges: res.Messages[0]?.Knowledge?.RelatedKnowledges, MessageId: res.MessageId }, 'left', 'text');
              }
              
              // 渲染成功推荐 
              if (resultObj.data.hasOwnProperty('next')) {
                appendMessage({ text: JSON.stringify({ data: resultObj.data['next'], code: 0 }) }, 'left', 'text');
              }
              // 无答案推荐
              if (resultObj.data && resultObj.data.is_no_answer && resultObj.data.is_no_answer === 1) {
                // 设置loading
                setTyping(true);
                recommendNoAnser({message: value}).then((res)=> {
                  if (res && res.info && Array.isArray(res.info.list) && res.info.list.length) {
                    appendMessage({ text: JSON.stringify( {data: Object.assign({}, res, {recommendType: 'is_no_answer'} ), code: 0 }) }, 'left', 'text');
                  }
                  setTyping(false);
                }).catch(()=> {
                  setTyping(false);
                })
              } else {
                // 有答案推荐 没有next属性就请求接口
                if (resultObj.data &&  resultObj.data.hasOwnProperty('is_chart') &&  resultObj.data.is_chart >= 0 && !resultObj.data.hasOwnProperty('next')) {
                  // 设置loading
                  setTyping(true);
                  RecommendMoreIntentMessages({trace_id: getTraceId(), function_id: +resultObj.data.function_id}).then(res=> {
                    if (res && res.info && Array.isArray(res.info.list) && res.info.list.length) {
                      appendMessage({ text: JSON.stringify( {data: Object.assign({}, res, {recommendType: 'is_yes_answer'} ), code: 0 }) }, 'left', 'text');
                    }
                    setTyping(false);
                  }).catch(()=> {
                    setTyping(false);
                  })
                }
              } 

              // open_url 跳转新建项目
              if (resultObj.data.hasOwnProperty('open_url')) {
                window.parent.postMessage({
                  open_url: resultObj.data.open_url,
                  message: 'signserOpenUrl',
                }, '*');
                // 手机端跳转
                if (['Android', 'iPhone'].indexOf(device) >= 0) {
                  const search = qs.parse(window.location.search.slice(1)) || {};
                  // 点击机器人进入
                  if (search.isWSEnter !== 'true') {
                    const corpid = qs.parse(window.location.search.slice(1)).corpid || '';
                    window.location.replace(resultObj.data.open_url + `&corpid=${corpid}`)
                    // dd.biz.navigation.replace({url: resultObj.data.open_url});
                  }
                  
                }
              }
              return;
            } else if (resultObj.code.toString().indexOf('402') === 0) {
              setSessionId(null);
              toast.fail(resultObj.msg);
              setError(true)
              setTyping(false);
            } else if (resultObj.code === -1) {
              setSessionId(null);
              toast.fail('请求错误');
              // setError(true)
              setTyping(false);
              return;
            } else {
              setSessionId(null);
              toast.fail('检测不到登录信息，请重试');
              setError(true)
              setTyping(false);
              return;
            }
          }
          appendMessage({ text: res.Messages[0].Text.Content, RelatedKnowledges: res.Messages[0]?.Knowledge?.RelatedKnowledges, MessageId: res.MessageId }, 'left', 'text');
        } else if (res.Messages[0].Type === 'Recommend') {
          appendMessage({ text: res.Messages[0].Recommends, RelatedKnowledges: res.Messages[0]?.Knowledge?.RelatedKnowledges, MessageId: res.MessageId }, 'left', 'Recommend');
        } else if (res.Messages[0].Type === 'Knowledge') {
          appendMessage({ text: res.Messages[0].Knowledge.Content, RelatedKnowledges: res.Messages[0]?.Knowledge?.RelatedKnowledges, MessageId: res.MessageId }, 'left', 'Knowledge')
        }
      }
    }).catch((err) => {
      setSessionId(null);
      setTyping(false);
    
      if (err.message == 'Network Error') {
  
        const avatar = user.avatar || defaultAvatar;
        updateMsg(textID, {
          type: 'text',
          content: {
            text: value
          },
          position: 'right',
          user: { avatar: avatar },
          status: true,
        })
        toast.fail('服务器错误');
        return
      }
      if (err.response?.status === 422) {
        toast.fail(err.response.data.msg);
      } else {
        const avatar = user.avatar || defaultAvatar;
        updateMsg(textID, {
          type: 'text',
          content: {
            text: value
          },
          position: 'right',
          user: { avatar: avatar },
          status: true,
        });
    
        toast.fail('服务器错误');
        return;
      }

    })
  }
  /**
   * @description 在子组件中调用 开始录音
   */
  function startVoice() {
    if (error) {
      toast.fail('检测不到登录信息，请重试');
      return
    }
    Recorder.getPermission().then(() => {
      setMaskStatus(true);
      setVoiceTime(60000);
      setContentStatus(0);
      setRealTimeContent({});
      setEndContent('');
      realTime.start();
    }, (error) => {
      toast.fail('请检查您电脑的麦克风设备是否正常开启')
    });

  }
  function aWord() {
    recorder.start().then(() => {
      const timer = setInterval(() => {
        setVoiceTime((params) => {
          if (params === 500) {
            clearInterval(timer)
          }
          return params -= 500
        })
      }, 500);
      setTimer(timer);
    })
  }
  /**
   * @description 接收侧边栏传过来的knowledge_id
   * @param {string} knowledge_id 侧边栏穿过来的knowledge_id
   */
  const sendFAQ = useDebounce((params) => {
    if (error) {
      toast.fail('检测不到登录信息，请重试');
      return
    }
    setSilderContent(params)
  }, 200)
  // 当Knowledge改变时触发这个方法
  useEffect(() => {
    if (knowledgeId) {
      update = false;
      send(knowledgeValue)
    }
  }, [knowledgeId])
  // 当有翻译内容或者文字时触发这个方法
  useEffect(() => {
    if (transContent) {
      update = false;
      send(transContent);
    };
  }, [transContent]);
  /**
   * @description 接收移动端的输入内容
   * @param {*} params 移动端的输入内容
   */
  function mobileSend(params) {
    if (error) {
      toast.fail('检测不到登录信息，请重试');
      return
    }
    setInputType(false);
    setSendType(1);
    setVoiceFileObject('');
    setTransType(0);
    setKnowledgeId(null);
    setTransContent(params);
    if (params === transContent) {
      update = false;
      send(params);
    }
    setSilderContent('');
  }
  /**
   * @description 接收pc端的输入内容
   * @param {*} params pc端的输入内容
   */
  function pcSend(params) {
    if (error) {
      toast.fail('检测不到登录信息，请重试');
      return
    }
    setKnowledgeId(null);
    setInputType(false);
    setSendType(1);
    setVoiceFileObject('');
    setTransType(0)
    setTransContent(params);
    
    if (params === transContent) {
      update = false;
      send(params);
    }
    setSilderContent('');
  }
  /**
   * @description 点击遮罩层
   */
  function handleMask() {
    if (maskStatus) {
      return
    }
    setDuration(null);
    setMaskStatus(false);
  }
  /**
   * @description 接收移动端语音
   * @param {*} params 移动端的语音内容
   */
  function mobileVoice(ddValue, stop) {
    if (error) {
      toast.fail('检测不到登录信息，请重试');
      return
    }
    // setTransType(1)
    // setSendType(2)
    // 修改sendType 和 TransType
    setSendType(2);
    setTransType(2);
    setKnowledgeId(null);
    setVoiceFileObject(JSON.stringify({
      voice_id: stop.medieId,
      from: 1,
      url: stop.remoteUrl,
      duration: stop.duration
    }))
    if (ddValue === '「语音未识别,请重试」') {
      const user = getSession('user') || {};
      appendMessage({ text: ddValue }, 'right', 'text', user.avatar || defaultAvatar);
      return
    }
    setTransContent(ddValue);
    if (ddValue === transContent) {
      update = false;
      send(ddValue);
    }
  }
  // 取消录音
  function cancel() {
    realTime.stop();
    clearInterval(timer);
    setMaskStatus(false)
    setDuration(null);
    setMaskStatus(false);
  }
  // 调音频期间的过渡动画
  function mobileVoiceParams(params) {
    if (params === true) {
      const user = getSession('user') || {};
      appendMessage('', 'right', 'trans', user.avatar || defaultAvatar);
    } else if (params === false) {
      deleteMsg(transID);
    }
  }
  // chatui顶部渲染函数，渲染最顶部的提示内容
  // function renderBeforeMessageList() {
  //   return ['Android', 'iPhone'].indexOf(device) >= 0 ? <MobileBeforeMessageList /> : <PCBeforeMessageList />
  // }
  // 
  function macCode(param, url = '') {
    setMacStatus(param);
    setGroupUrl(url);
  }
  return (
    <div className="chatBox" id="chatBox">
      {
        (process.env.REACT_APP_TOKEN || device) && allow() ?
          <>
            <MobileNav tipStatus={tipStatus} setTipStatus={setTipStatus} />
            <div className={!during && !maskStatus ? 'none' : 'mask'} onClick={handleMask}>
              <div className={during ? 'short-time' : 'none'}>
                <div>
                  <img alt="" src={signser} />
                </div>
                <div>
                  说话时间太短啦...无法识别哦
                </div>
              </div>
              <div className={maskStatus ? 'voiceing' : 'none'}>
                <div>
                  <div className="maikefengPC">
                    <img src={maikefengPC} />
                  </div>
                  <div>Signser正在聆听…</div>
                  <div id='trans-content'>{realTimeContent?.content}</div>
                  {/* {voiceTime > 10000 ? <div>您可以试试这样说：查询逾期合同、查询我跟进的合同</div> : <div>您还可以再说{Math.floor(voiceTime/ 1000)}秒</div>} */}
                  <div className="maikefeng-buttons"><button onClick={(e) => cancel()}>取消</button> <button style={{ background: '#FFFFFF', color: 'rgba(51, 119, 255, 1)' }} onClick={(e) => noneMask(e)}>我说完了</button></div>
                </div>
              </div>
            </div>
            {/* 取消头部内容 */}
            {/* renderBeforeMessageList={renderBeforeMessageList} */}
            {/* 取消搜索框头部快捷搜索内容 */}
            {/* quickReplies={quickReplies} */}
            <Chat
              messages={messages}
              quickReplies={ isShowFooterInput ? quickReplies : []}
              renderMessageContent={renderMessageContent}
              onQuickReplyClick={onQuickReplyClick}
              inputType="text"
              Composer={() => ['Android', 'iPhone'].indexOf(device) >= 0   ? <MInput isShowFooterInput={isShowFooterInput} mobileSend={mobileSend} mobileVoiceFun={mobileVoice} setVoiceFileObject={setVoiceFileObject} error={error} mobileVoiceParams={mobileVoiceParams} silderContent={silderContent}></MInput> : <FooterInput isShowFooterInput={isShowFooterInput} silderContent={silderContent} inputType={inputType} pcSend={pcSend} startVoice={startVoice} error={error} />}
            />
            {
              ['Android', 'iPhone'].indexOf(device) >= 0 ? '' : <div className={none ? "unfold" : "none"} onClick={() => setNone(false)} />
            }
            {/* <aside className={none || ['Android', 'iPhone'].indexOf(device) >= 0 ? 'none' : 'silder'}>
              <Silder pcSend={pcSend} macCode={macCode} deleteMsg={deleteMsg} kfID={kfID.current} childSetSilder={childSetSilder} sendFAQ={sendFAQ} appendMessage={appendMessage} />
            </aside> */}
            <QRcode macStatus={macStatus} macCode={macCode} url={groupUrl} />
            <div id="upload"></div>
          </> : <h1>不支持网页查看</h1>
      }
    </div>
  );
};

export default App;